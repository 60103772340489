<template>
  <div class="md:min-h-[calc(100vh-60px)] mt-[60px] text-center flex justify-center">
    <div class="md:max-w-screen-2xl pb-10 flex flex-col items-center justify-center">
      <div class="w-full md:w-2/3 flex flex-col bg-white py-4 px-10 md:p-10 md:pb-0 justify-between items-center overflow-hidden relative rounded-2xl lb-shadow md:shadow-none">
        <div>
          <h2 class="text-2xl font-bold mb-4">{{ trans('leakblock.emptyDashboard.welcomeTitle', { firstname: user.firstname })  }}</h2>
          <p class="text-gray-600">
            {{ trans('leakblock.emptyDashboard.noAccountsMessage') }}
          </p>
          <hr class="h-px mt-4 bg-gray-200 border-0">
        </div>
        <div class="flex bg-transparent border-t border-gray-200 rounded-2xl">
          <hr class="h-px my-8 bg-gray-200 border-0">
          <button @click="$emit('addNewAccount')" class="flex items-center text-white bg-primary-500 hover:bg-primary-400 px-4 py-2 my-5 transition rounded-md self-center ml-auto font-medium">
            {{ trans('leakblock.emptyDashboard.addAccountButton') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { trans } from "../../../../js/utilities/leakblock-translations";

  const props = defineProps({
    user: Object,
  });
</script>

<style scoped>
.lb-shadow {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
</style>
