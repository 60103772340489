.animation-pulse {
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 7px rgba(0, 0, 0, 0);
    }

    100% {
        transform: scale(0.95);
    }
}

.zoom-in {
    transition-property: transform,box-shadow;
    transition-duration: .3s;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    cursor: pointer
}

.zoom-in:hover {
    transform: scale(1.05);
    box-shadow: 0 20px 25px -5px #0000001a,0 8px 10px -6px #0000001a
}
