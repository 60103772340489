@import "utilities/animations.css";
@import "utilities/intro.css";
@import "../../node_modules/overlayscrollbars/styles/overlayscrollbars.css";
@import '../../node_modules/swiper/swiper.scss';
@import '../../node_modules/swiper/modules/free-mode.scss';
@import '../../node_modules/swiper/modules/scrollbar.scss';

@tailwind base;
@tailwind components;
@tailwind utilities;

.container {
    max-width: 100%;
}

.lb-shadow {
    box-shadow: 0 0px 9px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)
}

.lb-transparent-background {
    background-color: rgba(249, 250, 251, 0.75);
    backdrop-filter: blur(6px);
}

.slide-fade-enter-active, .slide-fade-leave-active {
    transition: all 0.3s ease-out;
}

.slide-fade-enter-from, .slide-fade-leave-to {
    transform: translateY(20px);
    opacity: 0;
}

.scroll-hint-top {
    background: linear-gradient(to bottom, rgba(229, 231, 235, 1), rgba(229, 231, 235, 0));
    transition: opacity 0.3s ease-in-out;
    z-index: 1;
}

.scroll-hint-bottom {
    background: linear-gradient(to top, rgba(229, 231, 235, 1), rgba(229, 231, 235, 0));
    transition: opacity 0.3s ease-in-out;
    z-index: 1;
}

.flex-shrink-0 :deep(svg) {
    width: 100%;
    height: 100%;
}

.box {
    box-shadow: 0 3px 5px #0000000b;
    background-color: #fff;
    border-radius: .6rem;
    position: relative
}

.navigation-button,
.add-account-button {
    @apply p-2 bg-white text-primary-500 rounded-md w-10 h-10 flex items-center justify-center hover:bg-primary-500 hover:text-white transition hover:border-primary-500 duration-300 lb-shadow z-10;
}

.navigation-button {
    @apply text-xs;
}

.lb-modal-content {
    @apply text-gray-600 !important;
}

.lb-modal-confirm {
    @apply px-4 py-2 text-sm font-medium text-white bg-gray-900 rounded-md hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 !important;
}

.lb-modal-cancel {
    @apply px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 !important;
}

.lb-modal-actions {
    @apply gap-2 !important;
}

/* Override SweetAlert2 icon animations if desired */
.swal2-icon {
    @apply border-gray-300 !important;
}

.swal2-success-line-tip,
.swal2-success-line-long {
    @apply bg-gray-900 !important;
}

.swal2-success-ring {
    @apply border-gray-200 !important;
}

.swal2-x-mark-line-left,
.swal2-x-mark-line-right {
    @apply bg-gray-900 !important;
}

#cc-main {
    --cc-btn-primary-bg: #000;
    --cc-btn-primary-color: #fff;
    --cc-btn-primary-hover-bg: rgba(0, 0, 0, 0.9);
}

#chat-bg {
    background-color: #e5e5f7;
    opacity: 0.2;
    background-image:  radial-gradient(#000000 0.55px, transparent 0.55px), radial-gradient(#000000 0.55px, #f3f4f6 0.55px);
    background-size: 22px 22px;
    background-position: 0 0,11px 11px;
}